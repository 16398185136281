.spo2-cursor {
  width: 100%;

  .cursor-bar {
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid var(--series-s700);
  }

  .bar {
    width: 100%;
    height: 3px;
    background-color: var(--series-s700);
  }

  .labels {
    display: flex;
    width: 100%;
    font-size: 12px;
    padding-top: 3px;
    min-height: 12px;

    .min {
      margin-left: -5px;
    }

    .avg {
      //margin-left: -5px;
    }
  }
}