.sh {
  &.card {
    background-color: white;
    padding: 12px 20px 20px 20px;
    border: none;
    & .card-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #eee;
      margin-bottom: 16px;
      padding-bottom: 8px;
    }
  }
}