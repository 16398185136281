.sh {
  &.btn-primary {
    color: #fff;
    border-radius: 0px;

    &.sm {
      height: auto;
      padding: 8px 16px;
    }

    &:hover {
      background-color: var(--primary-hover, $link-hover-color);
      color: $light;
    }

    &:active {
      color: $light;
    }

    &:focus {
      color: $light;
    }

    &:disabled {
      color: $light;
      //background-color: $primary;
      opacity: 0.3;
    }

    &.-outline {
      background-color: transparent;
      color: $primary;
      border-color: $primary;

      &:hover {
        color: $link-hover-color;
        border-color: $link-hover-color;
        background-color: rgba(237, 247, 248, 0.486);
      }
    }
  }
}