.wizard.wizard-3 {

  .wizard-nav {
    margin-bottom: 24px;

    .wizard-steps {
      padding-bottom: 12px;
      border-bottom: 1px solid $border-color;

      .wizard-step {
        margin: 0px;
        flex-grow: initial;

        .wizard-label {
          padding: 0px;
          font-size: 16px;

          .wizard-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            padding: 0px;
            font-size: 24px;
            opacity: 0.5;
            font-family: $font-family-base;

          }

          .wizard-bar {
            display: none;
          }
        }

        .icon {
          margin-left: 20px;
          filter: grayscale(1);
          opacity: 0.5;
        }

        &[data-wizard-state=current], &[data-wizard-state=done] {
          .icon {
            filter: initial;
            opacity: 1;
          }

          .wizard-label {

            .wizard-title {

              color: $primary-dark;
            }

            .wizard-bar {
              display: none;
            }
          }
        }

        &[data-wizard-state=current] {
          .wizard-title {
            font-weight: bolder;
            opacity: 1;
            font-family: $font-family-base-reg;

          }
        }

        &[data-wizard-state=done] {
          .wizard-title {
            opacity: 0.8;
          }
        }

        &:last-child {
          .icon {
            display: none;
          }

          .wizard-title {
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .div-custom {
    [data-wizard-TYPE=step-content] {
      h4.fw-bold {
        font-family: $font-family-base-reg;
        font-size: 24px;
        margin-top: 16px;
      }
    }

    .btn-row {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }


    h6.fw-bolder {
      font-size: 21px;
      color: $primary-dark;
      display: block;
      font-weight: 600 !important;
    }

    .fw-bolder {
      font-weight: 100 !important;
      font-size: 16px;
      color: $dark;
      display: block;
    }

    .text-dark-50.line-height-lg {

      div {
        margin-bottom: 12px;

        span:first-child {
          font-weight: 100;
          font-size: 16px;
          color: $dark;
          display: block;
        }

        span:last-child {
          font-size: 18px;
          color: $primary-dark;
          display: block;
        }
      }
    }
  }
}