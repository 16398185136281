$bgTHeader: #fff !default;
$bgTHeaderHover: #3a80bb !default;
$bgTHeaderHilight: #ffaf0b !default;
$bgTBody: #fff !default;
$colorTHeader: $primary-dark !default;
$fontFamilyTHeader: var(--main-font-family-sans-serif-light, $font-family-sans-serif) !default;
$fontSizeTHeader: 14px !default;
$fontSizeTBody: 16px !default;

$borderColorTRow: #ddd !default;
$colorTRow: $body-color !default;

$radiusTD: 0px !default;
