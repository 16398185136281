$widthComp: 26px;

.compliance-bars {
  width: $widthComp;
  display: flex;
  align-items: center;

  .bars {
    height: auto;
    display: flex;
    gap: 3px;
    align-items: center;

    .bar {
      background: #ccc;
      height: 16px;
      width: 4px;
    }
  }

  .empty {
    border: 1px solid #ccc;
    height: 18px;
    width: 18px;
  }

}

.compliance-heading {
  width: $widthComp;
  white-space: normal;
  line-height: 16px;
}

.compliance-text {
  &.text-error {
    color: var(--error) !important;
  }

  &.text-success {
    color: var(--primary) !important;
  }

  &.text-off {
    color: var(--gray) !important;
  }
}