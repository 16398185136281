.sh {
  &.filter-container {
    display: flex;
    align-items: center;

    .filter-list {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      margin-right: 12px;

      .filter-item {
        background-color: #E8ECF1;
        padding: 2px 6px;
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    .filter-dropdown-select {
      .btn-group {

        button {
          border-color: #D2D9E3;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          border-radius: 0px;
          padding: 7px;
        }
        &.open {
          .dropdown-menu {
            top: 40px;
            display: block;
          }
        }

        .dropdown-menu {
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          border-radius: 0px;
          border-color: #D2D9E3;
          min-width: 200px;
          z-index: 1100;

          li {
            padding: 3px 6px 3px 6px;

            &.item-list {
              cursor: pointer;
            }
          }

        }
      }
    }
  }
}