@import "~select2-component/dist/select2.min.css";


.select2-container {

  .select2-selection {
    height: var(--input-height, auto);
    border-radius: 0px;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    background-color: #ffffff;
    border: 1px solid $input-border-color;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    .select2-selection__rendered {
      line-height: $input-height;
      color: $primary-dark;
      font-size: 16px;
      padding-left: 12px;
    }

    .select2-selection__arrow {
      height: $input-height;

      & b {
        color: $primary;
        border-top-color: $primary;
        border-bottom-color: $primary;
      }
    }


  }

  .select2-dropdown {
    border: none;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

    .select2-results__option {
      font-size: 16px;

      &[aria-selected="true"] {
        background-color: #D2D9E3 !important;

        &.hidden {
          background-color: transparent !important;
        }
      }
    }
  }

  .select2-results__option {
    background-color: transparent !important;
    padding-left: 12px !important;

  }

  .select2-results__options {
    padding-left: 0px !important;
    max-height: none !important;

    &:focus {
      outline: none;
    }

    :focus-visible {
      outline: none;
    }
  }

  .select2-results__option--highlighted {
    background-color: rgba(210, 217, 227, 0.5) !important;
  }

  &.select2-container--open {
    &.select2-container--focus {
      .select2-selection {
        color: $primary-dark;
        background-color: #fff;
        border-color: #80d0f0;
        outline: 0;
        box-shadow: 0 0 0 0.25rem #00A0E13F;
      }
    }
  }

  & .select2-search--dropdown {
    & .select2-search__field {
      &:focus {
        color: $primary-dark;
        background-color: #fff;
        border-color: #80d0f0;
        outline: 0;
        box-shadow: 0 0 0 0.1rem #00A0E13F;
      }
    }
  }
}

.select2-container--default {
  .select2-selection--multiple {
    .select2-selection__rendered {
      padding-right: 22px;
      padding-top: 1px;
      padding-bottom: 2px;

      .select2-selection__choice {
        color: #333;
        background-color: #f0f0f0;
        border-radius: 0px;
        padding: 4px 12px;
        line-height: normal;
      }

      .select2-selection__choice__remove {
        margin-right: 6px;
      }

      &:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        color: #333;
        position: absolute;
        top: 20px;
        right: 10px;
      }
    }
  }
}

.placeholderSelectMulti {
  .select2-container--default {
    .select2-selection--multiple {

      .select2-selection__choice__remove {
        display: none !Important;
      }

      .select2-selection__choice {
        background-color: transparent;
        color: #333;
        border: none;
        margin-top: 5px;
      }

    }
  }

}

.select2-custom {
  &.error {
    .select2-selection {
      border-color: #b94a48;
      color: #a10000;
    }
  }
}