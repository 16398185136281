.swal2-container {
  z-index: 10600;

  .swal2-popup {
    box-shadow: $box-shadow;
    color: $primary-dark;

    .swal2-icon.swal2-warning {
      color: $warning;
      border-color: $warning;
    }

    .swal2-success-ring {
      color: $success;
      border-color: $success;
    }

    .swal2-actions {

      .swal2-styled.swal2-confirm {
        text-transform: uppercase;
        background-color: $primary;
        font-weight: 600;

        &:hover {
          background-color: $primary-hover;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}