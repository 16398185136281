.footer-action-bar {
  width: 100%;
  min-height: 64px;
  background-color: #005492;
  position: fixed;
  bottom: 0px;
  left: 0px;
  transform: translateY(100px);
  transition: all 0.5s;
  z-index: 10001 !important;

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-top: 12px;
  }

  &.show {
    transform: translateY(0px);
  }
}