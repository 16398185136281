@import "template_v";

.icon-svg-mask {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-size: auto;
  mask-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.icon-label-badge {
  margin: 2px;
  display: flex;

  .badgeInfo {
    cursor: help;
    padding: 2px 8px;
    display: inline;
  }
}

.icon-label-hover {
  margin: 2px;
  position: absolute;

  .badgeInfo {
    cursor: help;
    padding: 2px 8px;
    display: inline;
  }
}

.icon-label {
  display: flex;
  align-items: center;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    margin: 0px;
  }

  .icon {
    margin-right: 6px;
  }
}

.react-tiny-popover-container {
  z-index: 10005;
}


.status-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #009156;
}

.btn-icon-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--primary);
  position: relative;

  .notify {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--accent, #dc7800);
    top: -4px;
    right: -4px;

  }

  &.disabled {
    opacity: 0.3;
  }
}