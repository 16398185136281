.rmp-pad{
    width: 18.4rem !important;
}

.rmp-popup{
    width: 18.4rem !important;
}

.rmp-popup.range{
    width: 37.4rem !important;
}