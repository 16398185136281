@import "variables";

.shared-label {
  font-size: $fontSizeLabel;
  color: $colorLabel;

  &.subLabel {
    color: var(--gray-placeholder);
    font-family: var(--main-font-family-sans-serif-regular);
    font-size: 13px;
  }
}
