.title-select {
    border: none;
    font-size: 21px;
    cursor: pointer;
    color: #4d90fe;
    background-color: transparent;

    &:focus {
        outline: none;
    }
& option{
    font-size: 12px;
}

}