.subHeading {

    .subHeading-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        font-size: 16px;
        margin: 25px 0px 15px 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
    }
}