// Typography
$font-family-sans-serif: Arial, sans-serif;
$font-family-sans-serif-regular: Arial, sans-serif;
$font-size-base: 1rem; //16
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;


$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2; //32
$h2-font-size: $font-size-base * 1.75; //28
$h3-font-size: $font-size-base * 1.32; //21
$h4-font-size: $font-size-base * 1.13; //18
$h5-font-size: $font-size-base * 1.1; //
$h6-font-size: $font-size-base;


// Colors
$primary: #4d90fe;
$primary-dark: #005591;
$dark: #000;

//$secondary: #186680;
//$secondary-dark: #033B4E;
$gray: #DCE6EB;
$gray-200: #e9ecef;

//$bg-dark: #262626;
$bg-light: #F5F7F7;
$gray-dark: #6E7878;
$light: #fff;
$error: #dc3545;
$warning: rgb(220, 120, 0);
$success: rgb(0, 155, 60);
$success-light: #F6F9E8;

$grays: (
        "200": $gray-200,
);
$theme-colors: (
        "primary": $primary,
        "primary-dark": $primary-dark,
        "dark": $dark,
  //"secondary": $secondary,
  //"secondary-dark": $secondary-dark,
        "gray": $gray,
  //"bg-dark": $bg-dark,
        "bg-light": $bg-light,
        "gray-dark": $gray-dark,
        "light": $light,
        "error": $error,
        "warning": $warning,
        "success": $success,
        "success-light": $success-light,
);


// Body
$body-bg: $light;
$body-color: $primary-dark;
$gradient-gray: linear-gradient(46deg, rgba(237, 242, 245, 0.50) 0%, rgba(231, 236, 232, 0.50) 100%);
$gradient-light: linear-gradient(135deg, #F1F5F7 0%, #DCE6EB 100%);
$light-blue: #DCE6EB;
$light-bg: #F1F5F7;
$link-color: $primary;
$link-hover-color: #26aee6;
$link-decoration: none;

$border-color: #99ACBD;
$border-radius: 0rem;

$box-shadow: 0 1px 8px 0 rgba(0, 47, 90, 0.40);

$input-btn-padding-y: 0.563em;

$input-height: 44px;
$input-border-color: #ddd;
$labelColor: $dark;
$dropdown-link-hover-bg: $gray-200;

