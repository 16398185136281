
.heading {
  border-bottom: 1px solid #D8D8D8;

}

.heading-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  padding: 0 0 6px 0;
  font-size: 16px;
  margin: 6px 0 28px 0;
  border-bottom: 1px solid #ccc;

}
