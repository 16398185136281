@import "variables";

.shared-textArea {
  width: $widthTextArea;
  height: $heightTextArea;
  border-radius: $borderRadiusTextArea;
  font-size: $fontSizeTextArea;
  font-family: $fontFamilyTextArea;

  &.error {
    border-color: $error;
  }
}

