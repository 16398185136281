.panel-bottom {
  position: fixed;
  bottom: 0px;
  min-height: 100px;
  width: -webkit-fill-available;
  padding: 24px;
  box-shadow: var(--box-shadow);
  background: var(--light);

  .close-btn {
    display: flex;
    justify-content: flex-end;
  }
}