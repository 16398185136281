@import "bootstrap-daterangepicker/daterangepicker.css";

.daterangepicker {
  font-family: $font-family-base !important;
  box-shadow: $box-shadow;
  border: none;
  border-radius: 0px !important;

  & .ranges li.active {
    background-color: $primary !important;
    color: #fff;
  }
}

.date-picker {
  //z-index: 1051;
  &.show-calendar {
    padding-top: 0px;
  }

  &.single .ranges {
    display: none;
  }

  .drp-buttons {
    border-top: none;
  }

  .drp-buttons .btn {
    border-radius: 0px;
  }

  .drp-buttons button.btn-primary {
    border: none;
    background-color: $primary;
    color: #fff;
  }

  & .ranges {
    width: auto !important;
    margin: 0px;
    padding-top: 10px;
    float: right;
  }

  & .ranges ul {
    width: auto;
  }

  & .ranges li {
    width: auto;
    font-size: 13px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: $primary !important;
    padding: 6px 10px;
    margin-bottom: 8px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    cursor: pointer;
  }

  &.ranges li.active {
    background-color: $primary !important;
    color: #fff;
  }

  .ranges li.active,
  .ranges li:hover {
    color: #fff !important;
    background-color: $primary !important;

  }

  .drp-selected {
    display: none;
  }

  &.show-ranges.ltr .drp-calendar.left {
    border-left: none;
    border: 1px solid #ddd;
    margin: 10px;
    margin-right: 0px;
  }

  &.show-ranges.ltr .drp-calendar.right {
    border: 1px solid #ddd;
    margin: 10px;
    margin-right: 0px;
  }

  .calendar-table th,
  .calendar-table td {
    width: 32px;
    height: 32px;
    line-height: auto;
  }

  td.start-date.end-date,
  td.start-date,
  td.end-date {
    border-radius: 0px;
  }

  &.input-date-piker {
    input {
      cursor: pointer;
      min-width: 212px;
      padding: 7px 12px;
      padding-left: 32px;
      border: 1px solid #D2D9E3;
      background: url("icon/calendar.svg") no-repeat scroll 9px 9px;
      background-size: 14px;

      &:focus,
      &:focus-visible {
        border-radius: 0px;
        outline-style: outset;
        outline-width: 1px;
      }
    }

  }
}