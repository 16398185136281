@import "variables";

.shared-toast {
  margin-bottom: 4px;

  .toast {
    & .toast-header {
      min-height: 40px;
      font-size: 16px;
      background-color: transparent;
      padding: 12px;

      & .close {
        color: #fff;
        background-color: transparent;
        border: none;
        outline: none;

        span {
          font-size: 22px;
        }

        &.ms-2 {
          margin-left: 16px !important;
        }
      }
    }

    & .toast-body {
      padding: 12px 24px;
    }
  }
}
