
.title-h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0;
}

.title-h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.title-h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0;
}
