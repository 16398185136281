.progress-spinner {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
}

.spinner-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #000;
    opacity: .2;
}

.spinner-border {
    width: 5rem !important;
    height: 5rem !important;
    border-width: 0.45em !important;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
    position: absolute;
}