@import "./default";
@import "./primary";
@import "./secondary";
@import "./danger";

.text-btn {
  cursor: pointer;

  &:hover {
    color: $link-hover-color;
  }
}