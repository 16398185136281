@import "files";

.note-item {
  display: flex;

  .left-col {
    min-width: 120px;
    flex-basis: 10%;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    // margin-top: 15px;

    .date {
      margin-right: 20px;
    }

    .point-content {
      .point {
        min-width: 24px;
        width: 24px;
        height: 24px;
        background: #4480B7;
        border-radius: 50% !important;
      }
    }
  }

  .right-col {
    flex-basis: 90%;
  }


  .content {
    display: flex;
    flex-direction: column;

    .subject {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      margin-bottom: 10px;

      .sub-left-col {
        flex-grow: 1;

        .form-group {
          margin-bottom: 0px;
        }
      }

      .sub-right-col {
        flex-grow: 0;
      }

      .heading5 {
        font-weight: 600 !important;
        line-height: normal;
        margin: 0px;
        font-size: 15px;
      }
    }

    .note-updated {
      margin-top: 10px;

      display: flex;
      justify-content: space-between;
    }
  }

}