@import "variables";

.shared-boxFullScreen {
  background-color: $bgBoxFullScreen;
  width: 100%;
  height: 100%;
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & ~ div {
    position: fixed;
    z-index: 100001;
  }
}
