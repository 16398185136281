@import "variables";

.shared-checkbox {
  display: flex;
  align-items: center;
  //min-height: 64px;
  margin-bottom: 12px;
  cursor: pointer;

  .input-group-text {
    padding: 0px !important;
    display: flex;
    align-items: baseline;
  }

  input.checkbox-sh {
    visibility: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 22px;
    height: 22px;
    cursor: pointer;

    &:before {
      visibility: visible;
      appearance: auto;
      -webkit-appearance: auto;
      -moz-appearance: auto;
      font-size: $sizeEmpty;
      content: url("icon/checkbox-unchecked.svg");
      position: absolute;
      color: $colorCheckbox;
    }

    &:checked {
      &:after {
        visibility: visible;
        appearance: auto;
        -webkit-appearance: auto;
        -moz-appearance: auto;
        content: url("icon/checkbox-checked.svg");
        position: absolute;
        font-size: $sizeCheckbox;
        color: $colorCheckbox;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:active {
      &:before {

      }

      &:checked {
        &:after {

        }
      }
    }
  }


  & label {
    color: #000;
    margin-left: 8px !important;
    cursor: pointer;
  }


}

.check-item-single {
  display: flex;
  align-items: center;
}