.sh {
  &.dropdown {
    position: relative;

    .dropdown-menu {
      margin-top: 4px;
      margin-left: -8px;
      border: none;
      background: $light-blue;
      padding: 16px;

      .dropdown-item {
        color: $primary-dark;
        padding: 10px 4px;
        font-family: var(--main-font-family-sans-serif-light);
        cursor: pointer;
      }
    }
  }
}

.btn-group {
  &.dropstart {
    .sh {
      &.dropdown {
        .dropdown-menu {
          right: 0;
        }
      }
    }
  }

  &.right {
    .sh {
      &.dropdown {
        .dropdown-menu {
          right: -1px;
        }

      }
    }
  }
}


