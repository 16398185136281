#kt_content {
  margin-top: 72px;

  &.content {
    padding-top: 0px;
    padding-bottom: 0px;

    .layout-page {

      .filter-col {
        box-shadow: 6px 0 9px -7px rgba(0, 0, 0, 0.15);
        z-index: 2;
        padding-left: 16px;
        padding-right: 32px;
      }

      .content-col {
        padding-left: 32px !important;
        padding-right: 32px;
        //padding-left: 0px !important;
        padding-top: 12px;

      }
    }
  }
}

.title-page {
  font-family: $font-family-base-reg;
  margin-top: 20px;
}


.row.ae-page-content-container {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;

  .col-lg-2.ae-sidebar-content {
    flex-basis: auto;
    width: auto;
    padding: 0px;
    padding-right: 20px;

    .card {
      .card-body {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    & + .col-lg-10 {
      flex-grow: 1;
      width: 1%;
    }
  }

}

.var-height:not(.sticky-subHeader) + .content {
  margin-top: 136px !important;
}