@import "variables";

.pagination {
  margin-top: $marginTopPagination;
  display: flex;
  gap: 6px;

  .page-item {
    cursor: pointer;
    border: 1px solid #ddd;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.active {
      .page-link {
        background-color: $bgPagination;

        &:hover {
          background: $bgPagination;
          opacity: 1;
        }
      }
    }

    & .page-link {
      margin-left: 0px !important;
      border: none;
      padding: 7px 12px;
      font-size: $fontSizePagePagination;
      background-color: transparent;
      color: #333;

      &:focus {
        box-shadow: none;
      }

    }

    &:hover:not(.disabled) {
      background: none;
      opacity: 0.5;
    }

  }
}
