
.action-top-bar {
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  box-shadow: var(--boxShadow);
}


.btn-actions-toggle {
  display: flex;
  gap: 4px;
  justify-content: flex-start;

  .btn {
    &.btn-secondary {


      &.toogle-btn {
        background: var(--gray);
        color: var(--primary);
        font-family: var(--main-font-family-sans-serif-regular);
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        gap: 2px;

        &.checked {
          color: var(--light);
          background: var(--primary-dark);
        }
      }
    }
  }
}