
.sidebar-menu {
  z-index: 1001;
  position: relative;
  top: 0;
  bottom: 0;
  right: -395px;
  left: auto;
  overflow-y: auto;
  width: 286px;
  background: #ffffff;
  box-shadow: $box-shadow;
  padding: 2em;
  transition: all 0.3s ease;
  height: 100%;

  &.left {
    left: 0px;
    right: auto;
    transition: all 0.3s ease-in-out;
    padding: 1.5em;
    width: 62px;
    overflow: hidden;

    .sidebar-body {
      visibility: hidden;
      min-width: 200px;
      transition: all 0.2s ease-in-out;
    }

    &.in {
      left: 0;
      transition: all 0.3s ease-in-out;
      width: 286px;

      .sidebar-body {
        visibility: visible;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &.in {
    right: 0;
    transition: left 0.3s ease, right 0.3s ease;
  }

  .sidebar-header {
    display: block;
    text-align: end;
  }

  .sidebar-body {
    height: calc(100% - 20px);
  }

}