@import "variables";

.badgeMinMax {
  font-weight: bold;
  font-size: 15px;

  &.min {
    color: $blue;
  }

  &.max {
    color: $red;
  }
}
