.radio-row {
  display: flex;

  .radio-item {
    margin-right: 12px;
  }
}

.shared-radio {
  & label {
    margin-left: 8px;
  }

  .input-group-text {
    display: flex;
    align-items: baseline!important;
  }
}