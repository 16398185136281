.react-bootstrap-table {
  overflow-x: auto;

  table {
    &.table-head-custom {
      thead {
        tr {
          th {
            font-family: $font-family-base;
            font-weight: normal;
            letter-spacing: 0;
            color: $dark !important;
            font-size: 14px;
          }
        }
      }
    }

    &.table-vertical-center {
      th, td {
        vertical-align: baseline;
      }
    }

    tr {
      td, th {
        box-shadow: none;
        padding: 0.3rem !important;
      }
    }
  }

}