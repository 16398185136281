.sh {
  &.btn-secondary {
    color: $primary;
    border-color: $primary;
    background-color: $light;

    &:hover {
      background-color: rgba($link-hover-color, 0.5);
      color: $light;
    }

    &-outline {
      background-color: #fff;
      border: 1px solid #D2D9E3;
      color: #000;

      &:hover {
        background-color: #D2D9E3;
      }
    }
    
    &-outline-dark {
      background-color: #fff;
      border: 1px solid #333;
      color: #333;

      &:hover {
        background-color: #D2D9E3;
      }
    }

    &:hover {
      background-color: #D2D9E3;
    }

  }
}
