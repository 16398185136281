
.daterangepicker {
  .monthselect {
    border: none;
    color: $primary-dark;
  }

  .yearselect {
    border: none;
    color: $primary-dark;
  }
}

.month-picker {
  & > .rmp-container {
    .rmp-popup {
      &.range {
        width: 18rem !important;

        .rmp-pad {
          width: 8.5rem !important;
        }
      }

      &.light {
        border: none;
        color: $primary-dark;
        background-color: $light;

        &.range {
          .rmp-pad {
            background-color: $light;

            li {
              line-height: 2em;
            }

            label {
              font-size: 16px;
              line-height: 2em;
              color: $primary-dark;
            }

            & > div {
              i {
                line-height: 2.8em;
                height: 2.2em;
                width: 2.2em;
                color: #333;
              }
            }

            .rmp-btn {
              font-size: 12px;

              &.range {

                &.active {
                  background-color: #357ebd;
                }

                &.select {
                  background-color: #ebf4f8;
                }
              }
            }
          }
        }
      }
    }
  }
}

