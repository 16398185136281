.label-value {
  margin-bottom: 12px;

  .sh-label {
    font-size: 14px;
    line-height: normal;
    color: $dark;
    margin-bottom: 6px;

    &.sm {
      font-size: 14px;
    }

    &.lg {
      font-size: 18px;
    }

    &.bold {
      font-family: var(--main-font-family-sans-serif-regular);
      font-weight: bold;
    }

    &.medium {
      font-family: var(--main-font-family-sans-serif-regular);
      font-weight: normal;
    }
  }

  .value {
    font-size: 18px;
    line-height: normal;
    color: $primary-dark;

    &.bold {
      font-family: var(--main-font-family-sans-serif-regular);
      font-weight: bold;
    }

    &.medium {
      font-family: var(--main-font-family-sans-serif-regular);
      font-weight: normal;
    }

    &.sm {
      font-size: 14px;
    }
  }

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .sh-label {
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }
}

.text-primary-dark {
  color: $primary-dark !important;
}

.text-dark {
  color: $dark !important;
}