.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.inline {
    flex-direction: row;
    gap: 8px;

    .btn-label {
      margin-top: 0px;
    }
  }

  .btn-label {
    font-size: 14px;
    color: #75787B;
    font-weight: bold;
    margin-top: 8px;
  }

  &:hover {
    opacity: .8;
  }
}