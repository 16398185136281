.point-label {
  display: flex;
  gap: 4px;
  align-items: center;

  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: gray;
  }

  .label {
    font-size: 12px;
  }
}