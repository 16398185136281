.sh {
  &.btn-danger {
    background-color: #d84a38;
    border-color: transparent;
    color: white;

    &-outline {
      background-color: #fff;
      border: 1px solid #d84a38;
      color: #d84a38;

      &:hover {
        background-color: #D2D9E3;
        color: #fff;
      }
    }
  }

}