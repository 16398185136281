.sh {
  &.filter-container {
    display: flex;
    align-items: center;

    .filter-list {
      display: flex;
      gap: 4px;
      margin-right: 12px;

      .filter-item {
        background-color: #E8ECF1;
        padding: 2px 6px;
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    .filter-select {
      .btn-group {
        button {
          border-color: #D2D9E3;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          border-radius: 0px;
          padding: 9px 12px;
        }

        &.open {
          .dropdown-menu {
            top: 40px;
            display: block;
          }
        }

        .dropdown-menu {
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          border-radius: 0px;
          border-color: #D2D9E3;
          width: max-content;
          max-width: 330px;
          z-index: 1100;

          li {
            padding: 3px 8px 3px 8px;

            &.item-list {
              cursor: pointer;

              &.active {
                font-weight: bold;
              }
            }
          }

        }
      }
    }
  }

  &.filter-container-dropdown {
    .filter-select.filter-dropdown {
      .btn-group {
        button {
          color: #333;
        }
      }

      .dropdown-menu {
        color: #333;
        padding: 8px;

        li {
          padding: 3px 8px 3px 8px;

          &.item-list {
            cursor: pointer;
            padding: 8px 12px;

            &.active {
              font-weight: normal;
              background: #D2D9E3;;
            }

            &:hover {
              background-color: rgba(211, 218, 227, 0.5);
            }
          }
        }
      }
    }

  }
}