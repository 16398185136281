.sh {
  &.form-control {
    font-size: 16px;
    font-weight: normal;
    color: $primary-dark;
    background-color: #ffffff;
    border: 1px solid $input-border-color;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    height: auto;

    &.error {
      border-color: #b94a48;
      color: #a10000;
    }

    &.checkbox {
      width: 18px;
      height: 18px;
    }
  }
}