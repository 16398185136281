.row-notify {
  display: flex;
  align-items: center;
  gap: 24px;

  .date-col {
    width: 80px;
  }

  .number-col {
    display: flex;
    justify-content: center;
    width: 40px;
  }
}