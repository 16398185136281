.tag {
  display: inline;
  background: #009156;
  font-size: 14px;
  border-radius: 10px;
  padding: 2px 12px;
  color: $light;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: 0px;
  font-family: var(--main-font-family-sans-serif-light);
  line-height: normal;

  &.tag-alert {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 4px 12px;
    font-weight: normal;
    background-color: var(--gray);
    color: var(--primary-dark);
  }

  &.text-sm {
    font-size: 12px !important;
  }
}