.sh.popover-content {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 5px 15px #00000060;
  max-width: 335px;
  font-family: var(--main-font-family-sans-serif-light);
  color: $primary-dark;

  .popover-title {
    background: transparent;
    border: transparent;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 8px;
    font-family: var(--main-font-family-sans-serif-regular);
    font-weight: 700;
    font-size: var(--font-size-base);
    line-height: 24px;
    color: var(--table-head-color);
  }
}

.popover-arrow {
}