.card {
  background-color: transparent;

  &.card-custom {
    box-shadow: none;

    &.card-sticky {
      top: 64px;
      height: calc(100vh - 64px);
      margin-bottom: 0px !important;
    }

    .card-header {
      padding: 20px 6px 8px 0px;
      align-items: flex-end;
      border-bottom-color: $border-color;

      .card-title {
        margin: 0px;

        .card-label {
          font-weight: 100 !important;
          font-size: 21px;
          font-family: $font-family-base-reg;
        }
      }

      .card-toolbar {
        margin: 0;
      }

    }

    .card-body {
      padding: 16px 8px 16px 0px;
    }
  }
}
