.label-value {
  display: inline-block;
  flex-direction: column;
  min-width: 56px;
  padding-right: 16px;

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-right: 0px;
    min-width: auto;

    .label-comp {
      font-size: 12px;
      margin-right: 6px;
    }

    .value-comp {
      margin-right: 0px;
    }
  }

  .label-comp {
    font-size: 12px;
  }

  .value-comp {
    display: flex;
    align-items: baseline;
    font-size: 15px;
    margin-right: 16px;
  }
}