.sub-nav {
  width: 100%;
  max-height: 50px;
  min-height: 50px;
  overflow: hidden;
  transition: all 0.3s ease;

  .first-row, .inner-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;

    .back-btn {
      min-height: 42px;
    }
  }

  .inner-row {
    padding: 16px 10px;
    background: var(--white);
    margin-top: 12px;
  }

  &.isExpand {
    max-height: none;
    min-height: 200px;
    transition: all 0.3s ease;

    .inner-row {
      padding: 20px 10px;
      background: var(--white);
      margin-top: 12px;

    }
  }
}