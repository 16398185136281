@import "variables";
@import "mediaQuery";
//@import "font";

@import "bootstrap-components";

@import "bootstrap";
@import "custom";
@import "external_lib";
@import "table";


.btn-group-distance {
  display: flex;
  column-gap: 20px;
}

.v-hide {
  visibility: hidden;
}

.scroll-x {
  overflow-x: auto;
}

.w-100 {
  width: 100%;
}

label {
  font-weight: 400;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-sm {
  font-size: 14px !important;
}

.p-btn-icon {
  padding: 2px 6px !important;
}

svg > g > g.google-visualization-tooltip { pointer-events: none }


.custom-select{
  background-position-x: 100%;
  height: 42px;
  border:1px solid #d2e1eb;
  width: 100%;
  padding: 8px;
}