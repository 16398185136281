.css-b62m3t-container {
  .css-13cymwt-control {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid $input-border-color;
    color: #000;

    .css-1xc3v61-indicatorContainer {
      svg {
        display: none;
      }

    }
  }

  .css-t3ipsp-control {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(77 144 254 / 25%);
    border-radius: 0px;
    border-color: $input-border-color;
  }

  .css-13cymwt-control:hover {
    border-color: $input-border-color;
  }

  .css-qbdosj-Input {
    input {
      &::placeholder {
        color: #333;
      }
    }
  }

  .css-1nmdiq5-menu {
    margin-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .css-b62m3t-container {
    &.error {
      .css-1s2u09g-control {
        border-color: var(--error);
      }
    }

    &:hover {
      border: none;
    }
  }

  .css-tr4s17-option {
    background-color: #D2D9E3;
    color: #000;
  }

  .css-t3ipsp-control {
    border-color: #80d0f0;

    &:hover {
      border-color: #80d0f0;
    }
  }

  .css-d7l1ni-option {
    background-color: #d3dae380;

    &:active {
      background-color: #d3dae380;

    }
  }

  .select-caret {
    border-color: #888 transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    right: 6px;
    position: absolute;
    color: #000;
    border-top-color: #000;
    border-bottom-color: #000;

    &.caret-up {
      border-width: 0px 4px 5px;

    }

  }

}

