.list-files {
    display: flex;
    flex-wrap: wrap;

    &.column {
        flex-direction: column;

        .file-item {
            &:first-child {
                margin-top: 12px
            }

            .name-file {
                min-width: 260px;
            }

            margin-top: 4px;
        }
    }

    .file-item {
        margin-right: 16px;
        display: flex;
        align-items: center;


        .name-file {
            min-width: auto;

            a {
                color: #337ab7
            }
        }

    }
}