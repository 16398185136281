/*.card-header {
    padding: 1rem !important;
    font-size: 1.275rem !important;
}

.card-body {
    padding: 1rem !important;
}

.card-toolbar {
    margin: 0 !important;
}


.card-lg-10{
    padding-left: 5px !important;
}

.filter .form-group{
    margin-bottom: 1rem !important;
}

.customHeaderPadding .card-header{
    padding-top: .76rem !important;
    padding-bottom: .76rem !important;
}*/

.card-sticky {
    position: -webkit-sticky;
    /*Safari*/
    position: sticky !important;
    /*top: 119px;*/
}

.svg-icon.svg-icon-lg-detail svg {
    width: 150px !important;
    height: 150px !important;
}