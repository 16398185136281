.number-val {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: gray;
  color: white;
  font-family: var(--main-font-family-sans-serif-reguarl);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;

  &.lg {
    width: 42px;
    height: 42px;
  }
}