//@import 'bootstrap-daterangepicker/daterangepicker.css';


.daterangepicker {
  z-index: 10051;
  padding-top: 8px;

  &.single .ranges {
    width: auto;
  }
}