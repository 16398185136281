.badgeInfo {
  padding: 4px 12px;
  border: 1px solid transparent;
  border-radius: 2px !important;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  width: fit-content;

  &.inline {
    display: inline-flex;
  }

  &.warning {
    border-color: #A44F00;
    background-color: #ffefdb;
    color: #995400;
  }

  &.info {
    //border-color: #DC7800;
    background-color: rgba(255, 241, 178, 0.8);
    color: #DC7800;
  }

  &.variant-1 {
    //border-color: #A06E32;
    background-color: #F6E7D5;
    color: #99703D;
  }

  &.variant-2 {
    //border-color: #751822;
    background-color: #F6D5D5;
    color: #80071F;
  }

  &.variant-3 {
    //border-color: #772B7C;
    background-color: #F8D4F9;
    color: #772B7C;
  }

  &.variant-disabled {
    //border-color: #888;
    background-color: rgba(51, 51, 51, 0.1);
    color: #888;
  }
}