.title-menu {
  font-size: 15px;
  color: $primary-dark;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 36px;

  &:first-child {
    margin-top: 12px;
  }
}

.item-menu {
  font-size: 16px;
  color: $link-color;
  margin-bottom: 16px;
  //cursor: pointer;

  &:hover {
    color: $link-hover-color;
  }

  &.active {
    border-left: 4px solid $primary-dark;
    margin-left: -24px;
    padding-left: 20px;
    color: $primary-dark;
    font-weight: bold;
  }
}