.notify {
  width: 18px;
  height: 18px;
  background-color: $success;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 2px;
  font-weight: 600;
  font-family: $font-family-base;
}
