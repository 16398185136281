@import "variables";
@import "./components/pagination";

table.table-custom {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;

  thead {
    background: $bgTHeader;
    color: $colorTHeader;

    tr {
      font-family: $fontFamilyTHeader;
      font-size: $fontSizeTHeader;
      text-transform: capitalize;

      th {
        padding: 16px 16px;
        border: none;

        &:first-child {
          border-top-left-radius: $radiusTD;
          border-bottom-left-radius: $radiusTD;
        }

        &:last-child {
          border-top-right-radius: $radiusTD;
          border-bottom-right-radius: $radiusTD;
        }
      }
    }
  }

  tbody {
    tr {
      border: 1px solid $borderColorTRow;
      font-size: $fontSizeTBody;
      color: $colorTRow;

      td {
        padding: 8px 16px;
        //max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid $borderColorTRow;
        border-top: 1px solid $borderColorTRow;

        &:first-child {
          border-left: 1px solid $borderColorTRow;
          border-top-left-radius: $radiusTD;
          border-bottom-left-radius: $radiusTD;
        }

        &:last-child {
          border-right: 1px solid $borderColorTRow;
          border-top-right-radius: $radiusTD;
          border-bottom-right-radius: $radiusTD;
        }
      }

      &.rowActive {
        color: $colorTHeader;
        border-color: $colorTHeader;

        td {
          border-bottom-color: $colorTHeader;
          border-top-color: $colorTHeader;

          &:first-child {
            border-left-color: $colorTHeader;
          }

          &:last-child {
            border-right-color: $colorTHeader;
          }
        }

        &.hilight {
          color: $colorTHeader;
          border-color: $bgTHeaderHilight;

          td {
            border-bottom-color: $bgTHeaderHilight;
            border-top-color: $bgTHeaderHilight;

            &:first-child {
              border-left-color: $bgTHeaderHilight;
            }

            &:last-child {
              border-right-color: $bgTHeaderHilight;
            }
          }
        }
      }

      &.row-pointer {
        cursor: pointer;

        &:hover {
          border-color: $bgTHeaderHover;

          td {
            border-bottom-color: $bgTHeaderHover;
            border-top-color: $bgTHeaderHover;

            &:first-child {
              border-left-color: $bgTHeaderHover;
            }

            &:last-child {
              border-right-color: $bgTHeaderHover;
            }
          }
        }
      }
    }
  }

  &.solid {
    tbody {
      tr {
        background-color: $bgTBody;
        border: none;
      }

      td {
        border: none;

        &:first-child {
          border: none;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  &.stiky-right {
    thead {
      tr {
        th {
          &:last-child {
            background: #fff;
            position: sticky;
            right: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:last-child {
            background: #fff;
            position: sticky;
            right: 0;
            //box-shadow: -4px 0px 3px -2px rgb(0 0 0 / 50%);
            overflow: inherit;
          }
        }
      }
    }
  }

  &.sm {
    thead {
      tr {
        th {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          padding: 4px 16px;
        }
      }
    }
  }


}

.table-responsive {
  .sh-title-heading {
    margin-bottom: 0;

    .sh-heading {
      .left {
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
}

.table-responsive-xl {
  overflow-x: auto;
}

