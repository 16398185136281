@import "button";
@import "card";
@import "form";
@import "header";
@import "layout";
@import "sweetAlert";
@import "piker";
@import "table";
@import "wizard";

html, body, .flex-root {
  background: $white;
  -webkit-font-smoothing: antialiased;
}


.list-key-value {
  span.font-size-h6 {
    font-size: 14px !important;
    color: $gray-900;
  }

  span.font-size-sm {
    font-size: 18px;
    color: $primary-dark;
  }
}