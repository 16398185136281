.filter-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .btn-select-date {
        .btn {

            background-color: #08c;
            padding: 7px;
            font-size: 12px;
            font-weight: 300;
        }

    }
}