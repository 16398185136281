.form-group {
  h6.fw-bolder, label {
    font-weight: 100 !important;
    font-size: 16px;
    color: $dark;
  }

  .form-control {
    padding-left: 8px;
    padding-right: 8px;

    &.customPickerDisplay {
      font-size: 14px;

      .react-datepicker__close-icon {
        padding: 0px;

        &:after {
          background-color: transparent;
          color: $primary-dark;
          content: url(./assets/cross.svg);
          transform: scale(0.5);
        }
      }
    }

    &:disabled {
      opacity: 0.5;
    }

    &.is-valid {
      background-image: none;
    }

    &.is-invalid {
      background-image: none;
    }
  }

  .valid-feedback {
    display: none;
  }

  .invalid-feedback {
    color: $error;
  }
}

.checkbox {
  padding-left: 34px;

  & > span {
    border: 1px solid $input-border-color;
    background: transparent;
    width: 22px;
    height: 22px;
  }

  & > input:checked ~ span {
    border: 1px solid $input-border-color;
    background-color: transparent !important;

    &:after {
      content: url(./assets/check.svg);
      border: none;
      transform: rotate(0deg) scale(60%);
      width: auto;
      height: auto;
      margin-top: 2px;
      width: 100%;
    }
  }
}

.css-2b097c-container {
  .css-4j2rif-control {
    border: 1px solid $input-border-color;

    .css-1hb7zxy-IndicatorsContainer {
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
    }
  }
}
