@import "caption";
@import "heading";
@import "subheading";
@import "title";

.sh-heading {
  display: flex;
  justify-content: center;
  align-items: center;

  .left {
    margin-right: auto;
    width: 100%;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    margin-left: auto;
  }
}

.sh-title-heading {
  border-bottom: 1px solid $border-color;
  margin-bottom: 1.6em;

  h2 {
    margin-bottom: 0px;
  }

  &.heading-h5 {
    margin-bottom: 1em;

    h5 {
      margin-bottom: 8px;
    }

  }
}