.wizard-management {

  .sub-title {
    margin-bottom: 6px;
    font-size: 1.1em;
  }

  .wizard-step-title {
    display: flex;
    align-items: baseline;


    .step-title {
      display: flex;
      align-items: center;

      h3 {
        margin-bottom: 0px;
        line-height: 0px;
        //font-weight: 400;
      }

      .icon {
        margin: 0px 10px;
      }
    }

    & .step-title:last-child {

      h3 {
        font-weight: bold;
      }

      .icon {
        display: none;
      }
    }
  }
}