.sh {
  &.btn {
    width: auto;
    height: 44px;
    text-transform: uppercase;
    border-radius: 0px;
    line-height: normal;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    &.btn-default {
      background-color: $light;
      color: $link-color;
      padding-left: 0px;
      padding-right: 0px;
      padding: 12px 20px !important;

      &:hover {
        background-color: #F3F6F9 !important;
      }
    }

    &.default {
      background-color: $light;
    }

    &:hover {
      background-color: #F3F6F9;
    }

    &:focus,
    &:focus-visible {
      /*    outline: none;
      box-shadow: none; */
    }

    img {
      //margin-right: 8px;
    }
  }
}


