@import "table";

.sh.dropdown.filter-column {
  border: 1px solid #D2D9E3;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 9px 12px;
  font-size: 16px;

  .dropdown-toggle {
    color: #333
  }

  .dropdown-menu.show {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid #D2D9E3;
    min-width: 260px;
    z-index: 1100;
    background-color: #fff;
  }
}