.btn {
  text-transform: uppercase;
  font-family: $font-family-base-reg;

  &.btn-default {
    border: none;
  }
}

.custom-dropdown {
  border: none;
  background-color: transparent;
  color: $primary;
  font-family: $font-family-base-reg;

  &:hover {
    color: $primary-hover;
  }

  .custom-dropdown-menu {

  }


}

.navi {
  .navi-item {
    .navi-link {
      padding: 0.50rem 1.0rem;
      cursor: pointer;

      .navi-icon {
        flex: 0 0 1.5rem;
      }
    }
  }
}

.dropdown-box {
  position: absolute;

  .btn-group {

    .sh.dropdown.action-drop {
      .dropdown-menu {

        &.show {
          background: #fff;
        }

      }
    }

    &.right {
      .sh.dropdown.action-drop {
        .dropdown-toggle {
          &:after {
            display: none;
          }
        }

        .dropdown-menu {

          left: auto;
          right: 0px;
          width: auto;
        }
      }
    }
  }
}
